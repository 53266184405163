@mixin full-size {
    width: 100%;
    height: 100%;
}

.loading-screen {
    @include full-size;
    display: flex;
    align-items: center;
    justify-content: center;

    z-index: 100;
    position: absolute;

    background: #fff;

    .animation {
        $animation-size: 20vmin;
        width: $animation-size;
        height: $animation-size;
        // transform: rotate(45deg);

        // @media (prefers-reduced-motion: no-preference) {
            // animation: loader-rotater infinite 2s;
        // }

        img {
            @include full-size;
            filter: grayscale(90%) brightness(150%);
            opacity: 0.6;
        }
    }
}

//NOT WORKING FOR SOME REASON
// @keyframes loader-rotator {
//     0% {
//         transform: rotate(0deg);
//     }
//     50% {
//         transform: rotate(45deg);
//     }
// }