@import '../styles/selection-mixin.scss';
@import '../styles/center-mixin.scss';

$dimension: 50px;
$buffer: 5px;

.container {
  @include grid-center-children;
  @include select-none;

  border: 2px solid #777777;
  background-color: white;
  position: fixed;
  top: $buffer;
  left: $buffer; 
  width: $dimension;
  height: $dimension;
  z-index: 1;
  border-radius: $dimension;
}

.text {
  font-size: 1.75em;
}