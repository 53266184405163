@import './styles/selection-mixin.scss';

@media (min-width:320px)  { /* smartphones, portrait iPhone, portrait 480x320 phones (Android) */ }
@media (min-width:480px)  { /* smartphones, Android phones, landscape iPhone */ }
@media (min-width:600px)  { /* portrait tablets, portrait iPad, e-readers (Nook/Kindle), landscape 800x480 phones (Android) */ }
@media (min-width:801px)  { /* tablet, landscape iPad, lo-res laptops ands desktops */ }
@media (min-width:1025px) { /* big landscape tablets, laptops, and desktops */ }
@media (min-width:1281px) { /* hi-res laptops and desktops */ }

.App {
    text-align: center;

    .flying-object-container {
        overflow-x: hidden;
        width: 100%;
        height: 100%;
        position: absolute;
    }

    &-logo {
        height: 50vmin;
        user-select: none;
        
        @media (prefers-reduced-motion: no-preference) {
            animation: App-logo-float infinite 8s ease-in-out;
        }

        &.squish {
            animation: App-logo-squish 0.3s ease-out;
        }
    }

    &-header {
        // z-index: 100;
        min-height: 100vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        font-size: calc(10px + 2vmin);
    }

    &-logo-message {
        max-height: 11vmin;
        margin: 12px 0;
        // pointer-events: none;
        user-select: none;
        z-index: 1;

        &.squish {
            transform-origin: center;
            animation: App-logo-message-squish 1s ease-in;
        }
    }

    // &-message-email {
    //     @include select-all;
    // }

    &-message, &-header-bottom {
        // @include select-none;
        z-index: 1;
        font-family: sans-serif;
        font-size: calc(10px + 2vmin);
        letter-spacing: 0.8px;
        max-width: 90%;
        line-height: 1.5;
    }

    &-header-bottom {
        text-align: center;
        bottom: 0;
        font-size: calc(8px + 1vmin);;
    }
    
    a {
        color:  #9700d6;
        text-decoration: none;
    }
}
 


@keyframes App-logo-float {
    0% {
        transform: scale(1);
        transform-origin: center;
    }
    70% {
        transform: scale(1.03);
        transform-origin: center;
    }
}

@keyframes App-logo-squish {
    0% {
        transform: scale(1.3, 0.1);
        transform-origin: bottom;
    }
    80% {
        transform: scale(1);
        transform-origin: center;
    }
}

@keyframes App-logo-message-squish {
    $shrink: 0.6;

    0% {
        transform: scale($shrink) rotate(-8deg);
    }
    10% { transform: scale($shrink) rotate(7deg); }
    20% { transform: scale($shrink) rotate(-7deg); }
    27% { transform: scale($shrink) rotate(4deg); }
    35% { transform: scale($shrink) rotate(-4deg); }
    41% { transform: scale($shrink) rotate(3deg); }
    45% { transform: scale($shrink) rotate(-3deg); }
    85% {
        transform: scale($shrink*0.8);
        transform-origin: bottom;
    }
    100% {
        transform: scale(1) rotate(0);
        transform-origin: top;
    }
}